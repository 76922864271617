.table-row-cursor:hover {
    cursor: pointer;
}

.company-mng-btn {
    width: 120px;
    float: left;
    margin-right: 10px;
}

.selected-row {
    color: blue;
}

.company-list-container {
    height: 300px;
    overflow: auto;
}
