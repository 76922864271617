.table-row-cursor:hover {
    cursor: pointer;
}
.user-mng-btn {
    width: 120px;
    float: left;
    margin-right: 10px;
}

.table-header-row {
    background-color: dimgray !important;
    color: white !important;
}

