.app-menu {
    color: white !important;
    margin-right: 20px;
}

.badge-notify {
    background: red;
    position: absolute;
    top: 5px;
    left: 50px;
}