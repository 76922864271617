.fdm-datepicker {
    margin-right: 5px;
    border: 1px solid silver;
    width:160px;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    padding: 2px;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}